import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Text, { Section } from "../components/Theme";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import Header from "../components/Header";
// import Text from "../components/Theme";
import Footer from "../components/footer";
import Carousel from "nuka-carousel";
const Caption = styled(Text)`
    text-align: center;
    margin-bottom: 40px;
`;
const SlideButton = styled.button`
    font-size: 24px;
    padding: 10px 10px;
`;

const PageHeader = styled.h1`
    grid-column: 2/8;
    padding: 40px 0;
    font-weight: 100;
    font-size: 52px;
    line-height: 79px;
    ${media.lessThan("large")`
        padding-bottom: 20px;
        font-size: 36px;
        line-height: 48px;

    `}
`;
const CarouselFrame = styled(Carousel)`
    width: 100%;
    li {
        cursor: default;
    }
    .slider-frame {
        width: 100%;
    }
`;

const ImageCarousel = styled.div`
    grid-column: 2/12;
`;

const Video = styled.div`
    grid-column: 2/6;
    margin-bottom: 20px;
    video {
        width: 100%;
    }
`;
const IntroText = styled.div`
    grid-column: 6/12;
`;

const FeaturedVideo = styled.div`
    grid-column: 8/13;
    &:after {
        box-shadow: -65px 0 60px -30px #ffffff inset;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

const PageContent = styled.div`
    grid-column: 3/11;
    padding: 80px 0;
    ${media.lessThan("large")`
    padding-top: 20px;
    `}
`;
const LinkGrid = styled.div`
    display: grid;
    max-width: 1640px;
    width: 100%;
    grid-row-gap: 0px;

    grid-column: 2/12;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 80px;
    width: 100%;
    grid-column-gap: 50px;
    margin-bottom: 80px;
    ${media.lessThan("large")`
    display: block !important;
    `}
`;

const BookLink = styled.a`
    font-size: 18px;
    line-height: 22px;
    background: #000;
    color: #fff;
    display: block;
    text-align: center;
    padding: 20px 30px;
    text-decoration: none;
    margin-bottom: 10px;
`;

const TimelapseBook = ({ data: { book } }) => (
    <Layout parent="book-page">
        <HelmetDatoCms>
            <title>Overview Timelapse: How We Change The Earth</title>
            <meta
                property="og:title"
                content="Overview Timelapse: How We Change The Earth"
            />
            <meta property="og:description" content={book.description} />
            <meta
                property="og:image"
                content="https://www.datocms-assets.com/12893/1591390955-cover-shot-square.jpg"
            />
        </HelmetDatoCms>
        <Header content_color="black" />
        <Section>
            <PageHeader>
                <div
                    dangerouslySetInnerHTML={{
                        __html: book.title,
                    }}
                />
            </PageHeader>

            <FeaturedVideo>
                <video
                    autoPlay
                    muted
                    playsInline
                    loop
                    poster={book.featuredVideoPreloadImage.fluid.src}
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                    src={book.featuredVideo.url}>
                    <source src={book.featuredVideo.url} type="video/mp4" />
                    Your device does not support playing 'video/mp4' videos
                </video>
            </FeaturedVideo>
        </Section>
        <Section small>
            <Caption caption>Order here:</Caption>
            <LinkGrid>
                {book.links.map((link) => (
                    <div className={`book-${link.model.apiKey}`} key={link.id}>
                        {link.model.apiKey === "link" && (
                            <BookLink href={link.url} target="_blank">
                                {link.name}
                            </BookLink>
                        )}
                    </div>
                ))}
            </LinkGrid>
        </Section>
        <Section large>
            <Video>
                <video autoPlay muted loop playsInline>
                    <source src={book.introMedia.url} type="video/mp4" />
                </video>
            </Video>
            <IntroText>
                <div
                    className="body-copy"
                    dangerouslySetInnerHTML={{
                        __html: book.description,
                    }}></div>
            </IntroText>
        </Section>
        <Section>
            <ImageCarousel>
                <CarouselFrame
                    width="100%"
                    transitionMode="fade"
                    autoplayInterval={3000}
                    wrapAround
                    slideWidth={1.0}
                    initialSlidewidth={1.0}
                    autoplayz
                    renderCenterLeftControls={({ previousSlide }) => (
                        <SlideButton onClick={previousSlide}>
                            &larr;
                        </SlideButton>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                        <SlideButton onClick={nextSlide}>&rarr;</SlideButton>
                    )}>
                    {book.bookImages.map((img, index) => (
                        <Img fluid={img.fluid} alt="" key={`img-${index}`} />
                    ))}
                </CarouselFrame>
            </ImageCarousel>
            <PageContent>
                <div
                    className="body-copy"
                    dangerouslySetInnerHTML={{
                        __html: book.secondaryDescription,
                    }}></div>
            </PageContent>
            <Caption caption>Order here:</Caption>
            <LinkGrid>
                {book.links.map((link) => (
                    <div className={`book-${link.model.apiKey}`} key={link.id}>
                        {link.model.apiKey === "link" && (
                            <BookLink href={link.url} target="_blank">
                                {link.name}
                            </BookLink>
                        )}
                    </div>
                ))}
            </LinkGrid>
        </Section>
        <Footer />
    </Layout>
);

export default TimelapseBook;

export const query = graphql`
    query TimelapseBookQuery {
        book: datoCmsTimelapse {
            links {
                name
                url
                model {
                    apiKey
                }
            }
            title
            description
            secondaryDescription
            introMedia {
                url
            }
            bookImages {
                fluid(
                    maxWidth: 1600
                    imgixParams: {
                        dpr: 1
                        w: "1600"
                        fit: "clip"
                        auto: "compress"
                    }
                ) {
                    ...GatsbyDatoCmsFluid
                }
            }
            featuredVideo {
                url
            }
            featuredVideoPreloadImage {
                fluid(maxWidth: 1600, imgixParams: { w: "1600", fit: "clip" }) {
                    src
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }
`;
